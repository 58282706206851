import React, { useEffect } from "react";
import "./Css/404.css";
import thankyou from "./../Assets/Images/thankyou.png";
import Header from "./Header";
import { useParams } from "react-router-dom";
import { handleError, handleSuccess } from "../CommonJquery/CommonJquery.js";
import {
  server_post_data,
  complete_payment_from_web,
} from "../ServiceConnection/serviceconnection.js";
function CompletePayment() {
  const { id } = useParams();
  const master_data_get_update_data = async () => {
    const fd = new FormData();
    fd.append("payment_id", id);
    await server_post_data(complete_payment_from_web, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleSuccess(Response.data.message);
        }
      })
      .catch((error) => {
        handleError("network");
      });
  };

  useEffect(() => {
    master_data_get_update_data();
  }, []);

  return (
    <div>
      <Header />

      <div className="page_not_found d-flex justify-content-center">
        <div id="social_icons_scrolling"></div>
        <div id="footer_icons_hide"></div>
        <div className="text-center d-flex flex-column align-items-center">
          <img
            className="set_image_patc"
            src={thankyou}
            alt="shopup"
            title="shopup"
          />
          <div className="text_div">
            <h1>&nbsp;</h1>
            <span>
              <a href="/">Go back to Home.</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompletePayment;
