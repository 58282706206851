import React from "react";
import Footer from "./Footer";
import "./Css/PrivacyPolicy.css";
import Header from "./Header";

const RefundPolicy = () => {
  return (
    <div className="howitworks_wrapper">
      <Header />
      <div className="headerSection_howitWorks col-lg-12">
        <div className="terms_imageContainer">
          <div className="center_text_wrapper">
            <h3>Refund Policy</h3>
          </div>
        </div>
      </div>
      <div className="container privacy_policy_headingContainer">
        <h5>
          This Refund Policy describes how ShopUp ("we," "us," or "our")
          collects, uses, and protects the personal information you provide when
          you use our ecommerce mobile application (the "App"). By accessing or
          using the App, you consent to the terms of this Refund Policy.
        </h5>
        <div className="privacyPolicy_points">
          <ul>
            <li>
              1. Information We Collect
              <ul>
                <li>
                  1.1. Personal Information: When you create an account on the
                  App, place an order, or contact customer support, we may
                  collect personal information such as your name, email address,
                  shipping address, and payment details.
                </li>
                <li>
                  1.2. Usage Information: We may collect information about your
                  interactions with the App, including the pages you visit, the
                  products you view or purchase, and your preferences.
                </li>
              </ul>
            </li>
            <li>
              2. How We Use Your Information
              <ul>
                <li>
                  2.1. We use the personal information we collect to:
                  <ul>
                    <li>- Process and fulfill your orders.</li>
                    <li>
                      - Communicate with you about your orders, account status,
                      and promotions.
                    </li>
                    <li>- Improve the App and personalize your experience.</li>
                    <li>
                      - Provide customer support and respond to your inquiries.
                    </li>
                  </ul>
                </li>
                <li>
                  2.2. We may also use aggregated and anonymized data for
                  analytical purposes, such as analyzing trends and
                  demographics.
                </li>
              </ul>
            </li>
            <li>
              3. How We Protect Your Information
              <ul>
                <li>
                  3.1. We implement a variety of security measures to protect
                  the personal information you provide, including encryption and
                  secure server connections.
                </li>
                <li>
                  3.2. However, no method of transmission over the internet or
                  electronic storage is completely secure, so we cannot
                  guarantee absolute security.
                </li>
              </ul>
            </li>
            <li>
              4. Information Sharing and Disclosure
              <ul>
                <li>
                  4.1. We may share your personal information with third-party
                  service providers who assist us in operating the App,
                  processing payments, or fulfilling orders.
                </li>
                <li>
                  4.2. We may also share your information as required by law, to
                  enforce our policies, or to protect our rights, property, or
                  safety.
                </li>
              </ul>
            </li>
            <li>
              5. Third-Party Links and Services
              <ul>
                <li>
                  5.1. The App may contain links to third-party websites or
                  services that are not owned or controlled by us.
                </li>
                <li>
                  5.2. We are not responsible for the privacy practices or
                  content of these third-party websites or services.
                </li>
              </ul>
            </li>
            <li>
              6. Children's Privacy
              <ul>
                <li>
                  6.1. The App is not intended for use by children under the age
                  of 13. We do not knowingly collect personal information from
                  children under 13.
                </li>
              </ul>
            </li>
            <li>
              7. Changes to this Privacy Policy
              <ul>
                <li>
                  7.1. We reserve the right to update or modify this Privacy
                  Policy at any time. Any changes will be effective immediately
                  upon posting on the App.
                </li>
                <li>
                  7.2. We encourage you to review this Privacy Policy
                  periodically for any changes.
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RefundPolicy;
