import "./Css/BlogPage.scss";

import Header from "./Header";
import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import multiple_brands from "../Assets/Images/VectorBlog.png";

import { Link } from "react-router-dom";
import Footer from "./Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RightArrow from "../Assets/Images/rightArrow.svg";
import {
  server_post_data,
  get_blog_data_website,
} from "../ServiceConnection/serviceconnection.js";
import { handleLinkClick } from "../CommonJquery/CommonJquery.js";
import Slider from "react-slick";
const BlogPage = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth < 800);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [SEOloop, setSEOloop] = useState([]);
  const [addBlog, setaddBlog] = useState([]);
  useEffect(() => {
    master_data_get();
  }, []);

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/blog/blog_detail/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", "0");
    await server_post_data(get_blog_data_website, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          console.log(Response.data.message);
          setSEOloop(Response.data.message.seo_loop);
          setaddBlog(Response.data.message.blog_list);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const CustomCard = (landmar) => {
    return (
      <Link
        onClick={() =>
          handleLinkClick(match_and_return_seo_link(landmar.landmar.primary_id))
        }
        style={{ textDecoration: "none" }}
      >
        <Card style={{ cursor: "pointer" }}>
          <Card.Img
            variant="top"
            src={landmar.landmar.image_url}
            alt={landmar.landmar.title_name}
          />
          <Card.Body>
            <Card.Text>{landmar.landmar.title_name}</Card.Text>
          </Card.Body>
        </Card>
      </Link>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="rightArrow"
        style={{ display: "block", width: "40px", height: "40px" }}
        onClick={onClick}
      >
        <img
          src={RightArrow}
          alt="Next"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="LeftArrow"
        style={{ display: "block", width: "40px", height: "40px" }}
        onClick={onClick}
      >
        <img
          src={RightArrow}
          alt="Next"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    );
  };
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <div className="home" style={{ height: "100%", overflow: "hidden" }}>
      <div className="home_wrapper">
        <Header />
        <div className={showLoaderAdmin ? "loading" : ""}></div>
        <div className="home_container">
          <div className="BlogContentTT">
            <div className="container">
              <div className="BlogTitle">
                <h4>Blog</h4>
              </div>
              <div className="BlogContent">
                <p>
                  It is really hard to believe that small business owners have
                  not wished to see their business advertisements on social
                  media platforms like Instagram, Facebook or YouTube. During
                  the pandemic era, retail stores have suffered tremendously and
                  e-commerce apps have become a new shopping arena, therefore it
                  is time to lift your business from the ground to t{" "}
                </p>
              </div>
            </div>
          </div>

          {/* -----------------------blog down Section------------------- */}
          <section>
            <div className="container" style={{ paddingTop: "2rem" }}>
              {/* <div className="row">
                <div className="col-12 col-md-6">
                  <div className="multibrandImg  bloGcenterImg">
                    <img src={multiple_brands} alt="multiple brands" />
                  </div>
                </div>
                <div className="col BlogContent2 ">
                  <div className="container">
                    <div className="BlogTitle">
                      <h6 style={{ color: "#6e6e6e", fontWeight: "600" }}>
                        15 Apr. 2024
                      </h6>
                    </div>
                    <div className="BlogContentBody">
                      <p style={{ fontWeight: "400" }}>
                        The way people shop has been constantly evolving, isn’t
                        it? Remember when big malls and local markets were the
                        only places we went to buy things? Those big malls have
                        been compressed into small boxes on the mobile screen.
                        Over the course of time, traditional shopping has been
                        broken by the evolving eCommerce market and with this,
                        the way people market their brands has also evolved.
                        Today, it’s not just about distributing pamphlets or
                        promoting them on billboards. Businesses now have new
                        platforms and ways to reach out to their target
                        customers. Here in this article, we aim to cover some
                        important digital marketing strategies for Ecommerce
                        which can help you boost your sales. 
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </section>

          {/* -------------------blogs section------------------- */}
          {addBlog && addBlog.length > 0 && (
            <div className="container">
              <div className="blogs_section">
                <div className="blogHeading">
                  <h3> Read more logo posts on our blog</h3>
                </div>

                <div className="container">
                  {isDesktop ? (
                    <Slider {...settings2}>
                      {addBlog.map((card, index) => (
                        <div className="mobileBlogCarausel" key={index}>
                          <CustomCard landmar={card} />
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    <div className="container p-0">
                      <div
                        className="card_container"
                        style={{ paddingTop: "1rem" }}
                      >
                        <div className="row m-0 p-0">
                          <div className="col-md-4">
                            {addBlog.map((card, index) => (
                              <div key={index}>
                                <div className="desktopBlog">
                                  <CustomCard landmar={card} />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {/*------------Footer--------*/}
          <div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlogPage;
