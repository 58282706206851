import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./Css/blog2.scss";
import { Link, useLocation } from "react-router-dom";
import rightArrowGrey from "../Assets/Images/rightArrowgrey.svg";

import {
  server_post_data,
  blog_details_website,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleLinkClick,
  DateormateBlogChange,
} from "../CommonJquery/CommonJquery.js";
import DOMPurify from "dompurify";
const Blog2 = () => {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [showLoader, setShowLoader] = useState(false);
  const [VacancytData, setVacancytData] = useState([]);
  const [data_blog_extra, setdata_blog_extra] = useState([]);
  const [SEOloop, setSEOloop] = useState([]);
  const handleFetchData = async () => {
    setShowLoader(true);

    var form_data = new FormData();
    form_data.append("current_url", "/" + currentUrl);
    console.log(currentUrl);
    await server_post_data(blog_details_website, form_data)
      .then((Response) => {
        console.log(Response.data.message);
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          setVacancytData(Response.data.message.data_blog[0]);
          setdata_blog_extra(Response.data.message.data_blog_extra);
          setSEOloop(Response.data.message.data_seo);
        }
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/blog/blog_detail/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find(
        (data) => data_seo_link === data.call_function_name
      );

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }

    return data_seo_link_final;
  };

  //applying pagination to blog cards
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 9; // Adjust this number based on your design

  // Calculate pagination data
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = data_blog_extra.slice(indexOfFirstCard, indexOfLastCard);
  const totalPages = Math.ceil(data_blog_extra.length / cardsPerPage);

  const getPaginationRange = (currentPage, totalPages) => {
    const delta = 2;
    const range = [];
    const rangeWithDots = [];
    let l;

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - delta && i <= currentPage + delta)
      ) {
        range.push(i);
      }
    }

    for (let i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push("...");
        }
      }
      rangeWithDots.push(i);
      l = i;
    }

    return rangeWithDots;
  };

  const paginationRange = getPaginationRange(currentPage, totalPages);

  return (
    <>
      <Header />
      <div className={showLoader ? "loading" : ""}></div>
      <div className="blog_wrapper">
        <div className="headerSection">
          <div
            className="blogContents"
            style={{
              backgroundImage: `url(${VacancytData.image_url})`,
            }}
          >
            <div className="Blogs_heaading_wrapper flexRow">
              <h3>{VacancytData.title_name}</h3>
            </div>
            <div className="Blogs_heaading_description col-md-6 p-0">
              <p>{VacancytData.tag_line}</p>
            </div>
          </div>
        </div>
        {/*----------------------------------*/}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-3 m-0 p-0">
                <div className="d-flex h-100 align-items-start">
                  <div className="container">
                    <div className="main-heading">
                      <div>
                        <h6
                          style={{
                            fontSize: "14px",
                            marginTop: "6px",
                            fontWeight: "500",
                          }}
                        >
                          <label style={{ paddingRight: "10px" }}>
                            {DateormateBlogChange(VacancytData.entry_date)}
                          </label>
                          <label>2 minutes</label>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-8 m-0  p-0 ">
                <div className="container">
                  <div className="d-flex h-100 align-items-start">
                    <div className="main-heading">
                      <div>
                        <p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                VacancytData.description
                              ),
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*----------------------------------*/}
        {data_blog_extra && data_blog_extra.length > 0 && (
          <div className="container">
            <div className="row blogs_row"></div>
            <section className="blogs_section">
              <div className="container">
                <div className="blogs_container">
                  <div className="blogs_heading">
                    <h3>Read more logo posts on our blog</h3>
                  </div>
                  <div className="card_container">
                    {currentCards.map((card, index) => (
                      <Link
                        onClick={() =>
                          handleLinkClick(
                            match_and_return_seo_link(card.primary_id)
                          )
                        }
                        style={{ textDecoration: "none" }}
                      >
                        <div className="custom-card" key={index}>
                          <img src={card.image_url} alt="Blog" />
                          <p>{card.title_name}</p>
                        </div>
                      </Link>
                    ))}
                  </div>
                  <div className="pagination">
                    {paginationRange.map((page, index) =>
                      page === "..." ? (
                        <span key={index} className="dots">
                          ...
                        </span>
                      ) : (
                        <button
                          key={index}
                          onClick={() => setCurrentPage(page)}
                          className={page === currentPage ? "active" : ""}
                        >
                          {page}
                        </button>
                      )
                    )}
                    <button
                      onClick={() =>
                        setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                      }
                      disabled={currentPage === totalPages}
                    >
                      <img src={rightArrowGrey} alt="rightArrow" />
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
      {/*-----Footer------*/}
      <Footer />
    </>
  );
};

export default Blog2;
