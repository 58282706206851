import React from "react";
import { PopupModal } from "react-calendly";

const Calander = ({ show, handleClose }) => {
  return (
    <PopupModal
      url="https://calendly.com/shopupapp-dev/30min"
      pageSettings={{}}
      utm={{}}
      prefill={{}}
      onModalClose={handleClose}
      open={show}
      rootElement={document.getElementById("root")}
    />
  );
};

export default Calander;
