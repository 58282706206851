import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import "./Css/Home.scss";
import "./Css/ContactUs.scss";
import callIcon from "../Assets/Images/call.svg";
import emailIcon from "../Assets/Images/stacked_email.svg";
import loremIcon from "../Assets/Images/lorem.svg";
import faceVookIicn from "../Assets/Images/faceVookIicn.svg";
import insta from "../Assets/Images/insta.svg";
import xIcn from "../Assets/Images/x.svg";
import linkdin from "../Assets/Images/linkdin.svg";
import yt from "../Assets/Images/yt.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SubmitGif from "../Assets/Images/submitted.gif";
import {
  handleAphabetsChange,
  handleNumbersChange,
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleSuccess,
  handleError,
  handleEmailChange,
  handleLinkClick,
} from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  save_contact_us,
} from "../ServiceConnection/serviceconnection.js";
import Header from "./Header";

const ContactUs = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);
    if (vaild_data) {
      // setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("contact_request", "0");
      fd_from.append("payment_amount", "0");
      fd_from.append("package_name", "0");
      fd_from.append("package_id", "0");
      fd_from.append("total_days", "0");
      fd_from.append("payment_status", "pending");
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            empty_form(form_data);
            handleShow();
            setIsFilled(false);
            console.log("sdafsdfsdf");
          }
        })
        .catch((error) => {
          console.log(error);
          setshowLoaderAdmin(false);
        });
    }
  };

  const [formData, setFormData] = useState({
    user_name: "",
    user_contact: "",
    user_alternate_contact: "",
    user_email: "",
    user_city: "",
    user_msg: "",
  });

  const [isFilled, setIsFilled] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  console.log(formData);

  useEffect(() => {
    // const { user_name, user_contact, user_email, user_city, user_msg } =
    //   formData;
    if (
      formData.user_name.trim() &&
      formData.user_contact.trim() &&
      formData.user_email.trim() &&
      formData.user_city.trim()
      // && formData.user_msg.trim()
    ) {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }, [formData]);

  return (
    <div className="home" style={{ height: "100%", overflow: "hidden" }}>
      <div className="home_wrapper">
        <div className={showLoaderAdmin ? "loading" : ""}></div>
        <Header />
        <div className="container">
          <div className="contactCont">
            <div className="row">
              <div className="col-12 col-md-6 m-0 p-0">
                <div className="container">
                  <div
                    className="Callusside"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div className="container cont1">
                      <div className="collingSideBar">
                        <div className="collHeading marginBottom">
                          <h5 style={{ fontWeight: "700" }}>Contact us </h5>
                        </div>

                        <div className="callBtns">
                          <a href="#">
                            <img src={callIcon} alt="callicon"></img>+91
                            8005771838
                          </a>
                          <a href="#">
                            <img src={callIcon} alt="callicon"></img>+91
                            7290085276
                          </a>
                        </div>
                        <div className="callBtns">
                          <a href="info@shopupapp.in">
                            <img src={emailIcon} alt="emailicon"></img>
                            info@shopupapp.in
                          </a>
                        </div>
                        <div className="callBtns">
                          <p>
                            {" "}
                            <img src={loremIcon} alt="loremicon"></img>No.827
                            Ground Floor, 13th main, MEI Layout, Hessarghatta,
                            Bangalore -73
                          </p>
                        </div>
                        <div className="SocialMedia">
                          <button
                            onClick={() =>
                              handleLinkClick(
                                "https://www.facebook.com/shopupapk",
                                "1"
                              )
                            }
                          >
                            <img src={faceVookIicn} alt="fb"></img>
                          </button>
                          <button>
                            <img src={insta} alt="instagramicon"></img>
                          </button>
                          <button
                            onClick={() =>
                              handleLinkClick("https://x.com/shopupapk", "1")
                            }
                          >
                            <img src={xIcn} alt="xicon"></img>
                          </button>
                          <button
                            onClick={() =>
                              handleLinkClick(
                                "https://www.linkedin.com/company/shop-up-apk/",
                                "1"
                              )
                            }
                          >
                            <img src={linkdin} alt="linkedin"></img>
                          </button>
                          <button>
                            <img src={yt} alt="youtubeIcon"></img>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 m-0  p-0 h-100">
                <div className="container">
                  <div
                    className="requestCall"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div className="container  cont2">
                      <div className="requesTcollHeading">
                        <h5 style={{ fontWeight: "600" }}>
                          Request a Callback
                        </h5>
                      </div>
                      <div>
                        <p>
                          Shop up fun and friendly in-house customer success
                          team is available via live chat and email to answer
                          all your questions. Get help accessing files, tweaking
                          your query.
                        </p>
                      </div>
                      <div className="row inputsBox">
                        <form
                          className="createRightsForm contctFormL"
                          id="createRightsForm"
                        >
                          <div className="col-12 mb-3">
                            <label>
                              Name<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              name="user_name"
                              min={3}
                              max={10}
                              placeholder="Enter Your Name"
                              className="form-control trio_mandatory"
                              onChange={(e) => {
                                handleInputChange(e);
                                handleAphabetsChange(e);
                              }}
                            ></input>
                          </div>
                          <div className="inline-flex">
                            <div className="col-6 col-sm-6 mb-3">
                              <div className="input-container">
                                <label>
                                  Mobile No.
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  name="user_contact"
                                  placeholder="Enter Your Mobile No."
                                  min={3}
                                  max={10}
                                  className="form-control trio_mandatory trio_no"
                                  onChange={(e) => {
                                    handleInputChange(e);
                                    handleNumbersChange(e);
                                  }}
                                ></input>
                              </div>
                            </div>
                            <div className="col-6 col-sm-6 mb-3">
                              <div className="input-container">
                                <label>Alternate Mob. No.</label>
                                <input
                                  type="text"
                                  name="user_alternate_contact"
                                  placeholder="Alternate Mob. No."
                                  min={3}
                                  max={12}
                                  className="form-control trio_no"
                                  onChange={handleNumbersChange}
                                ></input>
                              </div>
                            </div>
                          </div>
                          <div className="col-12  mb-3">
                            <label>
                              Email<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              name="user_email"
                              placeholder="Enter Your Email"
                              min={3}
                              className="form-control trio_mandatory"
                              onChange={(e) => {
                                handleInputChange(e);
                                handleEmailChange(e);
                              }}
                            ></input>
                          </div>
                          <div className="col-12  mb-3">
                            <label>
                              City<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              name="user_city"
                              placeholder="Enter Your City"
                              min={3}
                              max={10}
                              className="form-control trio_mandatory"
                              onChange={(e) => {
                                handleInputChange(e);
                              }}
                            ></input>
                          </div>
                          <div className="col-12 mb-3">
                            <label>Your Query</label>
                            <textarea
                              style={{ height: "100px" }}
                              type="text"
                              name="user_msg"
                              min={3}
                              max={200}
                              placeholder="Enter your Query"
                              className="form-control"
                              onChange={(e) => {
                                handleInputChange(e);
                              }}
                            ></textarea>
                          </div>
                        </form>
                      </div>
                      <div className="SubmitBtn">
                        {isFilled ? (
                          <button
                            type="btn"
                            style={{
                              backgroundColor: "var(--blueHover)",
                              color: "var(--white)",
                            }}
                            onClick={() =>
                              handleSaveChangesdynamic(
                                "createRightsForm",
                                save_contact_us
                              )
                            }
                          >
                            Submit
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              handleSaveChangesdynamic(
                                "createRightsForm",
                                save_contact_us
                              )
                            }
                            type="btn"
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*------------Footer--------*/}
        <div>
          <Footer />
        </div>
      </div>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="direcColkumn d-flex align-items-center">
          <img src={SubmitGif} alt="Thank you" />
          <p className="m-0">
            <strong>Thank you for reaching out!</strong> We have received your
            query and will get back to you shortly.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ContactUs;
