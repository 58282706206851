import React, { useState } from "react";
import "./Css/Footer.css";
import { Link } from "react-router-dom";
import fb_logo from "../Assets/Images/footer_fb.svg";
import ig_logo from "../Assets/Images/footer_ig.svg";
import x_logo from "../Assets/Images/footer_twitter.svg";
import linkedIn_logo from "../Assets/Images/footer_linkedIn.svg";
import footer_social_logo from "../Assets/Images/footer_yt.svg";
import arrow from "../Assets/Images/fotter_Arrow.svg";
import { Link as ScrollLink } from "react-scroll";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleSuccess,
  handleError,
  handleEmailChange,
  handleLinkClick,
} from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  save_subscribe,
} from "../ServiceConnection/serviceconnection.js";
const Footer = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response);
          setshowLoaderAdmin(false);
          handleSuccess("Subscription successful");
          empty_form(form_data);
        })
        .catch((error) => {
          console.log(error);
          handleError("error");
          setshowLoaderAdmin(false);
        });
    }
  };
  return (
    <>
      {/* ------------------------footer-section----------------------- */}
      <section className="footer_Section">
        <div className={showLoaderAdmin ? "loading" : ""}></div>
        <div className="container">
          <div className="footer_container">
            <footer className="page-footer pt-4">
              <div className="container-fluid">
                <div className="row flexRowCenterEvenSpace">
                  {/* First links column */}
                  <div className="col-md-4 footer_column1">
                    <h3>About us</h3>
                    <div className="footer_column4_text">
                      <p>
                        Shopup is an eCommerce SaaS platform that enables
                        businesses to launch their own eCommerce app, website,
                        and POS system within 24 hours.
                      </p>
                    </div>
                    {/* Add your company logo and social links here */}
                    <div className="footer_column4_socials">
                      <div className="footerLinks">
                        <a
                          onClick={() =>
                            handleLinkClick(
                              "https://www.facebook.com/shopupapk",
                              "1"
                            )
                          }
                          className="social-icon"
                        >
                          <img src={fb_logo} alt="social_icon" />
                        </a>
                        <a href="#!" className="social-icon">
                          <img src={ig_logo} alt="social_icon" />
                        </a>
                        <a
                          onClick={() =>
                            handleLinkClick(
                              "https://www.linkedin.com/company/shop-up-apk/",
                              "1"
                            )
                          }
                          className="social-icon"
                        >
                          <img src={linkedIn_logo} alt="social_icon" />
                        </a>
                        <a
                          onClick={() =>
                            handleLinkClick("https://x.com/shopupapk", "1")
                          }
                          className="social-icon"
                        >
                          <img src={x_logo} alt="social_icon" />
                        </a>
                        <a href="#!" className="social-icon">
                          <img src={footer_social_logo} alt="social_icon" />
                        </a>
                      </div>
                    </div>
                    {/* ================ */}
                  </div>

                  {/* Third links column */}
                  <div className="col-md-4  footer_column3 flexColCenter linkContnr">
                    <h5 className="footer_column1_heading">Links</h5>
                    <ul
                      className="list-unstyled "
                      style={{ cursor: "pointer" }}
                    >
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>

                      <li>
                        <a href="/refundPolicy">Return & Refund policy</a>
                      </li>
                      <li>
                        <ScrollLink
                          className="nav-link"
                          to="howIget"
                          smooth={true}
                          duration={200}
                          offset={-window.innerHeight / 18}
                        >
                          How it works
                        </ScrollLink>
                      </li>
                      <br />
                      <li>
                        <h5 className="footer_column1_heading">Newsletter</h5>
                        <div className="footer_email_label_container">
                          <label>Email *</label>
                          <form id="createRightsForm2">
                            <div className="w-100 position-relative">
                              <input
                                type="email"
                                name="admin_email"
                                onInput={handleEmailChange}
                                className="form-control footer_email_input trio_mandatory trio_email"
                              />
                              <span className="condition_error text-danger"></span>
                              <button
                              className="send_email"
                                type="button"
                                onClick={() =>
                                  handleSaveChangesdynamic(
                                    "createRightsForm2",
                                    save_subscribe
                                  )
                                }
                              >
                                {" "}
                                <img src={arrow} alt="arrow" />
                              </button>
                            </div>
                          </form>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {/* Fourth column for company logo and social links */}
                  <div className="col-md-4 footer_column4">
                    <h5 className="footer_column3_heading">Company</h5>
                    <ul className="list-unstyled footer_column4_lis">
                      <li>
                        <Link to="/contactUs">Contact</Link>
                      </li>
                      <li>
                        <a href="#!">Career</a>
                      </li>
                      <li>
                        <Link to="/privacyPolicy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/termsofuse">Terms & condition </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
        <div className="footer_trisoft_copyright_section ">
          <hr />
          <h6 style={{ fontWeight: "400" }}>
            © 2024 Copyright by Prapp Technologies Pvt. Ltd. All rights
            reserved. Designed and Developed by{" "}
            <a
              href="https://www.triosoft.ai/"
              target="_blank"
              style={{ color: "black" }}
            >
              Triosoft Technologies
            </a>{" "}
            Pvt. Ltd.
          </h6>
        </div>
      </section>
    </>
  );
};

export default Footer;
