import React, { useEffect, useState } from "react";
import "./Css/Home.scss";
import "./Css/BlogPage.scss";
import { Link } from "react-router-dom";
import Header from "./Header";
import Calander from "./Calander";
import Card from "react-bootstrap/Card";
import multiple_brands from "../Assets/Images/multiple-brands.png";
import upArrow from "../Assets/Images/downArrowBlack.svg";
import productIcn from "../Assets/Images/producTicn.svg";
import OrderIcon from "../Assets/Images/ordericn.svg";
import analyticcn from "../Assets/Images/analyticcn.svg";
import androidIcn from "../Assets/Images/androidIcn.svg";
import shopingIcn from "../Assets/Images/shopingIcn.svg";
import unlimitedProductIcn from "../Assets/Images/unlimitedProductIcn.svg";
import unlimitedProductIcn2 from "../Assets/Images/unlimitedProductIcn2.svg";
import unlimitedProductIcn3 from "../Assets/Images/unlimitedProductIcn3.svg";
import unlimitedProductIcn4 from "../Assets/Images/unlimitedProductIcn4.svg";
import unlimitedProductIcn5 from "../Assets/Images/unlimitedProductIcn5.svg";
import unlimitedProductIcn6 from "../Assets/Images/unlimitedProductIcn6.svg";
import PhoneSHopUp from "../Assets/Images/shop2.png";
import RightArrow from "../Assets/Images/rightArrow.svg";
import secondDivimg from "../Assets/Images/mainHero.webp";
import whyLA01 from "../Assets/Images/whyLA01.svg";
import whyLA02 from "../Assets/Images/whyLA02.svg";
import whyLA03 from "../Assets/Images/whyLA03.svg";
import whyLA04 from "../Assets/Images/whyLA04.svg";
import whyLA05 from "../Assets/Images/whyLA05.svg";
import whyLA06 from "../Assets/Images/whyLA06.svg";
import Footer from "./Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  server_post_data,
  get_blog_data_website,
} from "../ServiceConnection/serviceconnection.js";
import { handleLinkClick } from "../CommonJquery/CommonJquery.js";
const Home = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth < 800);
  const [showModal, setShowModal] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [SEOloop, setSEOloop] = useState([]);
  const [addBlog, setaddBlog] = useState([]);

  const handleClose = () => setShowModal(false);
  const items = [
    {
      title: "What is Shopup ?",
      content:
        "Shopup is a IT company which provide white label solution (SAAS Platform) aiming to help individuals, small, medium enterprises to digitise their offline business with their own quick commerce - eCommerce app.",
    },
    {
      title: "How to get demo of Shopup platform?",
      content:
        "Send us enquiry from our website or give us call our team will connect you within an hour.",
    },
    {
      title: "How can I get white label app for my business?",
      content:
        "After taking demo from our manager, you can directly pay from our website or pay to our manager, after successful payment within ____ Days you will get you app.",
    },
    {
      title: "Who will manage my app?",
      content:
        "We will give you training for managing the app, After training if you have any doubt you can always connect to our support team.",
    },
    {
      title: "Can I get refund of my payment?",
      content:
        "Yes within 24 Hour of payment, after that no refund will be applicable.",
    },
    {
      title: "Can I delist my app anytime?",
      content: "Yes you can.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/blog/blog_detail/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", "0");
    await server_post_data(get_blog_data_website, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          console.log(Response.data.message);
          setSEOloop(Response.data.message.seo_loop);
          setaddBlog(Response.data.message.blog_list);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  const CustomCard = (landmar) => {
    return (
      <Link
        onClick={() =>
          handleLinkClick(match_and_return_seo_link(landmar.landmar.primary_id))
        }
        style={{ textDecoration: "none" }}
      >
        <Card style={{ cursor: "pointer" }}>
          <Card.Img
            variant="top"
            src={landmar.landmar.image_url}
            alt={landmar.landmar.title_name}
          />
          <Card.Body>
            <Card.Text>{landmar.landmar.title_name}</Card.Text>
          </Card.Body>
        </Card>
      </Link>
    );
  };
  const cardsData = [
    {
      img: productIcn,
      title: "Product management",
      desc: "Ability to add multiple images and product descriptions, bulk import and edit",
    },
    {
      img: OrderIcon,
      title: "Order management",
      desc: "Ability to manage orders and track shipments, bulk import and edit",
    },
    {
      img: analyticcn,
      title: "Analytics",
      desc: "Ability to view and analyze data, generate reports and insights",
    },
    {
      img: androidIcn,
      title: "Android",
      desc: "Ability to add multiple images and product descriptions, bulk import and edit",
    },
    {
      img: shopingIcn,
      title: "Shopping",
      desc: "Ability to add multiple images and product descriptions, bulk import and edit",
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="rightArrow"
        style={{ display: "block", width: "40px", height: "40px" }}
        onClick={onClick}
      >
        <img
          src={RightArrow}
          alt="Next"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        className="LeftArrow"
        style={{ display: "block", width: "40px", height: "40px" }}
        onClick={onClick}
      >
        <img
          src={RightArrow}
          alt="Next"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    );
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <div className="home" style={{ height: "100%", overflow: "hidden" }}>
      <div className="home_wrapper">
        <Header />
        <div className={showLoaderAdmin ? "loading" : ""}></div>
        <div className="home_container">
          <div className="hero paddingX991">
            <div className="row h-100">
              <div
                className="col-12 col-lg-6 col-md-7 p-0 firstContHero"
                style={{ marginLeft: "auto" }}
              >
                <div className="container h-100">
                  <div className="d-flex h-100 align-items-center position-relative">
                    <div className="HeroContent">
                      <p>
                        Transform Your Retail Vision Launch Your eCommerce App
                        with Shopup
                      </p>
                      <div className="herobottmcont">
                        <label>
                          Launch Your Online Business in Just 24 Hours with
                          Shopup's Affordable eCommerce Solutions!
                        </label>
                      </div>
                      <div className="herobottmcont heroFotter">
                        <label>• User Management</label>
                        <label>• Inventory Management</label>
                        <label>• Order Processing</label>
                        <label>• Customer Engagement</label>
                      </div>

                      <div className="scheduleDemoBTN">
                        <Link to="/contactUs">
                          <button
                            className="btn freedemoBtn"
                            type="button"
                            //nClick={handleShow}
                          >
                            Schedule Free Demo
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="seconHero1 d-block d-md-none">
                      <img src={secondDivimg} alt="alt tag"></img>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-12 col-lg-5 col-md-5 p-0 h-100"
                style={{ marginRight: "auto" }}
              >
                <div className="seconHero w-100 h-100">
                  <img src={secondDivimg} alt="alt tag"></img>
                </div>
              </div>
            </div>
          </div>

          {/* -----------------------SmartSection----------------- */}
          <div className="col-md-11 m-auto">
            <section>
              <div className=" smartContainer">
                <div className="">
                  <div className="smartEasyHead ">
                    <div className="SmartAndEasy">
                      <h3>
                        Smart and easy to use, no-code dashboard and friendly UI
                        themes for app and website
                      </h3>
                    </div>
                  </div>
                  <div className="whatyouget_subheading">
                    <h6
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      You can get an eCommerce website and apps in 24 hours
                    </h6>
                  </div>
                </div>
                <div className="">
                  {isDesktop ? (
                    <Slider {...settings}>
                      {cardsData.map((card, index) => (
                        <div className="mobileSizeCarausel">
                          <div key={index} className={`card${index + 1} crdd`}>
                            <div className="cententCarausel">
                              <img src={card.img} alt={card.title} />
                              <h6>{card.title}</h6>
                              <p>{card.desc}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    <div className="cardContainer">
                      <div className="smartcArds">
                        <div className="cart1">
                          <img src={productIcn} alt="productIcon"></img>
                          <h6 className="font14">
                            Product <br /> Management
                          </h6>
                          <p>
                            Ability to add multiple images and product
                            descriptions, bulk import and edit
                          </p>
                        </div>
                        <div className="cart1">
                          <img src={OrderIcon} alt="orderIcon"></img>
                          <h6 className="font14">
                            Order <br /> management
                          </h6>
                          <p>
                            Track, and fulfill customer orders, as well as
                            process returns and exchanges
                          </p>
                        </div>

                        <div className="cart1">
                          <img src={shopingIcn} alt="shopingIcon"></img>
                          <h6 className="font14">
                            Shopping cart <br /> and checkout
                          </h6>
                          <p>
                            A seamless and easy checkout experience for better
                            customer conversion.
                          </p>
                        </div>
                        <div className="cart1">
                          <img src={androidIcn} alt="androidIcon"></img>
                          <h6 className="font14">
                            Consumer Android <br /> & iOS App
                          </h6>
                          <p>
                            Publish app to play store and app store and make
                            customer sticky to your products
                          </p>
                        </div>
                        <div className="cart1">
                          <img src={analyticcn} alt="analyticIcon"></img>
                          <h6 className="font14">
                            Analytics and <br /> reporting
                          </h6>
                          <p>
                            Detailed analytics and reporting on sales, customer
                            behavior, and website traffic
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <br />
            </section>
            {/* -----------------------Everything you need Section------------------- */}
            <section>
              <div className="container-lg ">
                <div className="main-heading">
                  <div className="SmartAndEasy smartContainer2">
                    <h3>
                      Everything you need to know to manage your online and
                      offline business with complete no code solution
                    </h3>
                  </div>
                  <div
                    className="whatyouget_subheading"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <h6
                      style={{
                        fontSize: "14px",
                        marginTop: "6px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      With Shopup, create your own brand’s ecommerce app,
                      website and sync it with an ePOS if you have your offline
                      store
                    </h6>
                  </div>
                </div>
              </div>

              <div className="container" style={{ paddingTop: "2rem" }}>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="multibrandImg">
                      <img src={multiple_brands} alt="multiple brands" />
                    </div>
                  </div>
                  <div className="col unique_right_contain ">
                    <div className="main-heading2">
                      <h3>Sell without limits. Everything Unlimited</h3>
                      <div
                        className="whatyouget_subheading"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <h6
                          style={{
                            fontSize: "14px",
                            marginTop: "6px",
                            fontWeight: "400",
                          }}
                        >
                          Sell unlimited with easy to use eCommerce website and
                          app. Shopup is the best platform to take your business
                          to the next level
                        </h6>
                      </div>
                    </div>

                    <div className="unlimitedSectionImgs">
                      <div className="row">
                        <div className="col-6">
                          <div className="d-flex ">
                            <img
                              src={unlimitedProductIcn}
                              alt="unlimitedProductIcon"
                            ></img>
                            <p>Unlimited product</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="d-flex ">
                            <img
                              src={unlimitedProductIcn2}
                              alt="unlimitedProductIcon"
                            ></img>
                            <p>Unlimited categories</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="d-flex">
                            <img src={unlimitedProductIcn3} alt="alt tag"></img>
                            <p>Unlimited customers</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="d-flex">
                            <img src={unlimitedProductIcn4} alt="alt tag"></img>
                            <p>Unlimited orders</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container" style={{ paddingTop: "3rem" }}>
                <div
                  className="row"
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  <div className="col-12 col-md-6">
                    <div className="multibrandImg">
                      <img src={PhoneSHopUp} alt="multiple brands" />
                    </div>
                  </div>
                  <div className="col unique_right_contain ">
                    <div className="main-heading2">
                      <h3>Email Notifications. SMS Alerts,</h3>
                      <div
                        className="whatyouget_subheading"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <h6
                          style={{
                            fontSize: "14px",
                            marginTop: "6px",
                            fontWeight: "400",
                          }}
                        >
                          Never miss out on any communication SMS and Email to
                          keep your customers engaged.
                        </h6>
                      </div>
                    </div>
                    <div className="container">
                      <div className="unlimitedSectionImgs w-100">
                        <div className="row w-100">
                          <div className="col-6 p-0">
                            <div className="d-flex w-100">
                              <img
                                src={unlimitedProductIcn5}
                                alt="alt tag"
                              ></img>
                              <p>SMS alerts </p>
                            </div>
                          </div>
                          <div className="col-6 p-0">
                            <div className="d-flex w-100">
                              <img
                                src={unlimitedProductIcn6}
                                alt="alt tag"
                              ></img>
                              <p>Email notifications,</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/*----------- How to get Everything ----------  */}

            <section id="howIget" className="about_section">
              <div className="container">
                <div className="faq_headings">
                  <div className="main-heading" style={{ padding: "0px" }}>
                    <h3>How to get started?</h3>
                  </div>

                  <div className="faq_subheading">
                    <h6>
                      Get started in 5 minutes with Shopup's easy onboarding
                      process and design and launch your own brand website and
                      mobile app in just 24hrs at an affordable price
                    </h6>
                  </div>
                </div>
                <div style={{ paddingTop: "2rem" }}>
                  <div className="container">
                    <div className="row">
                      <div className="white-card col-lg-6 col-md-6">
                        <div className="card-content">
                          <h2 className="card-heading">
                            <img src={whyLA01} alt="whyLA01" />
                            Get your own white label app
                          </h2>
                          <p className="card-subheading">
                            Shopup solutions enables business owners to take an
                            omni-channel approach by providing a an e-commerce
                            app, an ecommerce website and an ePOS. Thus,
                            providing a complete ecosystem to manage their both
                            offline and online business.
                          </p>
                        </div>
                      </div>
                      {/* card2 */}
                      <div className="white-card col-lg-6 col-md-6">
                        <div className="card-content">
                          <h2 className="card-heading">
                            <img src={whyLA02} alt="whyLA01" />
                            Sell your product online.
                          </h2>
                          <p className="card-subheading">
                            Having your own IT team or hiring an IT company for
                            your eCommerce setup is way expensive. With Shopup
                            smooth and affordable technology, you focus on your
                            business, not tech.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row2 row">
                      {/* card3  */}
                      <div className="white-card col-lg-6 col-md-6">
                        <div className="card-content">
                          <h2 className="card-heading">
                            <img src={whyLA03} alt="whyLA01" />
                            Create your product category.
                          </h2>
                          <p className="card-subheading">
                            There has been a conscious attempt to ensure our
                            platform is completely no-code platform and follows
                            a very logical and user-friendly interface so that
                            anyone or any age and without any technical
                            knowledge can choose to take their business online.
                          </p>
                        </div>
                      </div>
                      {/* card4  */}
                      <div className="white-card col-lg-6 col-md-6">
                        <div className="card-content">
                          <h2 className="card-heading">
                            {" "}
                            <img src={whyLA04} alt="whyLA01" />
                            Recieve your payment online.
                          </h2>
                          <p className="card-subheading">
                            We consider our customers our best critic, testers
                            and biggest contributors to our product development.
                            We at Shopup has a robust support and query
                            management system. We constantly add new features
                            and innovate the product.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row3 row">
                      {/* card5  */}
                      <div className="white-card col-lg-6 col-md-6">
                        <div className="card-content">
                          <h2 className="card-heading">
                            <img src={whyLA05} alt="whyLA01" />
                            List your product.
                          </h2>
                          <p className="card-subheading">
                            Shopup has helped businesses generate millions of
                            sales across India and growing day by day.
                          </p>
                        </div>
                      </div>
                      {/* card6  */}
                      <div className="white-card col-lg-6 col-md-6">
                        <div className="card-content">
                          <h2 className="card-heading">
                            {" "}
                            <img src={whyLA06} alt="whyLA01" />
                            Track your orders/Delivery.
                          </h2>
                          <p className="card-subheading">
                            Running a business using a SaaS platform requires
                            great tech and non-tech support. Shopup offers
                            different kind support depending on your plan. With
                            our enterprise plan, you get priority support to
                            resolve your queries as soon as possible.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </section>
            {/*----------- frequently asked questions ----------  */}

            <div className="container" id="FaqSection">
              <div className="faq_section_container">
                <div className="faq_headings">
                  <div className="main-heading" style={{ padding: "0px" }}>
                    <h3>Frequently Asked Questions</h3>
                  </div>

                  <div className="faq_subheading">
                    <h6>Find your answer to frequently asked questions</h6>
                  </div>
                </div>
                <div className="accordion_container col-md-14">
                  <div className="accordion">
                    {items.map((item, index) => (
                      <div key={index} className="accordion-item">
                        <div
                          className={`accordion-title ${
                            index === activeIndex ? "active" : ""
                          }`}
                          onClick={() => handleClick(index)}
                        >
                          {item.title}
                          <span className="dropdown-icon">
                            <img src={upArrow} alt="downarow" />
                          </span>
                        </div>
                        {index === activeIndex && (
                          <div className="accordion-content">
                            <p className="accordion-content-text">
                              {item.content}
                            </p>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* -------------------blogs section------------------- */}
            {addBlog && addBlog.length > 0 && (
              <div className="container">
                <div className="blogs_section">
                  <div className="blogHeading">
                    <h3 style={{ textAlign: "center" }}>
                      {" "}
                      Read more logo posts on our blog
                    </h3>
                  </div>

                  <div className="container">
                    {isDesktop ? (
                      // <Slider {...settings2}>
                      <>
                        {addBlog.slice(0, 3).map((card, index) => (
                          <div className="mobileBlogCarausel" key={index}>
                            <CustomCard landmar={card} />
                          </div>
                        ))}
                      </>
                    ) : (
                      // </Slider>
                      <div className="container p-0">
                        <div
                          className="card_container"
                          style={{ paddingTop: "1rem" }}
                        >
                          <div className="row m-0 p-0">
                            <div>
                              {addBlog.slice(0, 3).map((card, index) => (
                                <div key={index}>
                                  <div className="desktopBlog">
                                    <CustomCard landmar={card} />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* -------------------Brand section------------------- */}
            <div className="container">
              {" "}
              <div className="brandContainer">
                <div className="faq_headings">
                  <div className="main-heading" style={{ padding: "0px" }}>
                    <h3>We’re here to help build your brand</h3>
                  </div>

                  <div className="faq_subheading">
                    <h6>
                      Join Shopup and take your business online within 24 hours
                      as other businesses with completely no code e-commerce
                      platform
                    </h6>
                  </div>
                  <div className="ScheduleDemoBtn">
                    <Link to="/contactUs">
                      <button type="button">Schedule a Demo</button>
                    </Link>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>

          {/*------------Footer--------*/}
          <div>
            <Footer />
          </div>
        </div>
      </div>
      {/*Calander Model Show */}
      <Calander show={showModal} handleClose={handleClose} />
    </div>
  );
};
export default Home;
