import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Home from "./Components/Home";
import Pricing from "./Components/Pricing";
import TermsandConditions from "./Components/TermsandConditions";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import BlogPage from "./Components/BlogPage";
import ContactUs from "./Components/ContactUs";
import CompletePayment from "./Components/CompletePayment";
import Blog2 from "./Components/Blog2";
import ScrollToTop from "./ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  server_post_data,
  get_seo_data_website,
} from "./ServiceConnection/serviceconnection.js";
import PageNotFound from "./Components/404.js";
import RefundPolicy from "./Components/RefundPolicy.js";
function App() {
  const [SEOloop, setSEOloop] = useState([]);
  const match_and_return_seo_link_and_page = (
    call_function_name,
    pretty_function_name,
    dynamic_title_ss,
    favicon_sss
  ) => {
    let data_seo_link_final = "";

    if (call_function_name === "/") {
      data_seo_link_final = pretty_function_name + "~@~1";
    } else if (call_function_name === "/pricing") {
      data_seo_link_final = pretty_function_name + "~@~2";
    } else if (call_function_name === "/contactUs") {
      data_seo_link_final = pretty_function_name + "~@~3";
    } else if (call_function_name === "/termsofuse") {
      data_seo_link_final = pretty_function_name + "~@~4";
    } else if (call_function_name === "/privacyPolicy") {
      data_seo_link_final = pretty_function_name + "~@~5";
    } else if (call_function_name === "/blog") {
      data_seo_link_final = pretty_function_name + "~@~6";
    } else if (call_function_name.includes("blog/blog_detail")) {
      data_seo_link_final = pretty_function_name + "~@~7";
    }
    return data_seo_link_final;
  };

  const get_page_name = (page_number) => {
    let data_seo_link_final = "";

    if (page_number === "1") {
      data_seo_link_final = <Home />;
    } else if (page_number === "2") {
      data_seo_link_final = <Pricing />;
    } else if (page_number === "3") {
      data_seo_link_final = <ContactUs />;
    } else if (page_number === "4") {
      data_seo_link_final = <TermsandConditions />;
    } else if (page_number === "5") {
      data_seo_link_final = <privacyPolicy />;
    } else if (page_number === "6") {
      data_seo_link_final = <BlogPage />;
    } else if (page_number === "7") {
      data_seo_link_final = <Blog2 />;
    }

    return data_seo_link_final;
  };

  const handleFetchData = async () => {
    await server_post_data(get_seo_data_website, null)
      .then((Response) => {
        if (!Response.data.error) {
          setSEOloop(Response.data.message.seo_loop);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/termsofuse" element={<TermsandConditions />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/refundPolicy" element={<RefundPolicy />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/blog_detail/:id" element={<Blog2 />} />

          {SEOloop.map((data, index) => {
            const for_loop_come = match_and_return_seo_link_and_page(
              data.call_function_name,
              data.pretty_function_name,
              data.title,
              data.favicon
            );
            const stringArray = for_loop_come.split("~@~");

            return (
              <Route
                key={index}
                path={stringArray[0]}
                element={get_page_name(stringArray[1])}
              />
            );
          })}
          <Route path="/complete_payment/:id" element={<CompletePayment />} />
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
