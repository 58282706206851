import axios from "axios";
const appauth_key = "barlays@2029";
let APL_LINK = "http://192.168.111:8000/";
APL_LINK = "https://backend.shopupapp.in/";
let Website_URL = "https://www.shopupapp.in/";
let local_server_link_react = APL_LINK + "api/website_link/";
const StripeKey =
  "pk_test_51PVU9cP7GdOA24IdEhPhESdS6asxh2Ctyf28ru6lB2PrFInUsOwN97ThBPqp4OkLnxfkSZy3IEtIJIDrZaN1wKSb00s5VlmoGS";
// API functions

//get last upload imgs
const get_blog_data_website = local_server_link_react + "get_blog_data_website";
const get_seo_data_website = local_server_link_react + "get_seo_data_website";
const blog_details_website = local_server_link_react + "blog_details_website";
const get_all_packages = local_server_link_react + "get_all_packages";

const save_contact_us = local_server_link_react + "add_admincontectus_mainweb";
const save_subscribe = local_server_link_react + "save_subscribe";
const intent_for_payment = local_server_link_react + "create_payment_intent";
const complete_payment_from_web =
  local_server_link_react + "complete_payment_from_web";
/**website links */

const server_post_data = async (url_for, form_data) => {
  if (form_data === null) {
    form_data = new FormData();
  }
  return axios.post(url_for, form_data);
};

export {
  APL_LINK,
  Website_URL,
  appauth_key,
  server_post_data,
  save_contact_us,
  get_blog_data_website,
  get_seo_data_website,
  blog_details_website,
  get_all_packages,
  save_subscribe,
  intent_for_payment,
  complete_payment_from_web,
  StripeKey,
};
