import React, { useEffect, useState } from "react";
import "../Components/Css/Pricing.css";
import upArrow from "../Assets/Images/downArrowBlack.svg";
import Header from "./Header";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { Modal, Button, Form } from "react-bootstrap";
import {
  server_post_data,
  get_all_packages,
  intent_for_payment,
  StripeKey,
} from "../ServiceConnection/serviceconnection.js";
import DOMPurify from "dompurify";
import { loadStripe } from "@stripe/stripe-js";
import {
  handleAphabetsChange,
  handleNumbersChange,
  check_vaild_save,
  combiled_form_data,
  handleError,
  handleEmailChange,
  handleLinkClick,
} from "../CommonJquery/CommonJquery";
const Pricing = () => {
  const stripePromise = loadStripe(StripeKey);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [addBlog, setaddBlog] = useState([]);
  const [SelectedData, setSelectedData] = useState([]);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [searchfilter, setSearchFilter] = useState("");
  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", "0");
    await server_post_data(get_all_packages, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          setaddBlog(Response.data.message.data_packages);
          const filteredDatashow = Response.data.message.data_packages.filter(
            (row) => {
              return row.type_of_days === 2;
            }
          );
          setSearchFilter(filteredDatashow);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const closeModel = () => {
    setShowLoginModal(false);
  };
  const openModel = (dataset) => {
    setSelectedData(dataset);
    // setShowLoginModal(true);
  };
  const handlePlanChange = (searchValue) => {
    setPlanType(searchValue);
    // Filter table data based on search value
    const filteredDatashow = addBlog.filter((row) => {
      return row.type_of_days === parseInt(searchValue);
    });

    setSearchFilter(filteredDatashow);
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderAdmin(true);
      const stripe = await stripePromise;
      let fd = combiled_form_data(form_data, null);
      fd.append("payment_amount", SelectedData.package_price);
      fd.append("package_name", SelectedData.package_name);
      fd.append("package_id", SelectedData.primary_id);
      fd.append("total_days", SelectedData.total_days);
      fd.append("contact_request", "1");
      fd.append("payment_status", "pending");
      await server_post_data(url_for_save, fd)
        .then(async (Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            let sessionId = Response.data.message.client_secret;
            const { error } = await stripe.redirectToCheckout({
              sessionId,
            });

            if (error) {
              console.error("Error:", error);
            }
          }
        })
        .catch((error) => {
          handleError("network");
          setshowLoaderAdmin(false);
        });
    }
  };

  // Example image URLs
  const items = [
    {
      title: "What is Shopup ?",
      content:
        "Shopup is a IT company which provide white label solution (SAAS Platform) aiming to help individuals, small, medium enterprises to digitise their offline business with their own quick commerce - eCommerce app.",
    },
    {
      title: "How to get demo of Shopup platform?",
      content: "Content for Accordion Item 2",
    },
    {
      title: "How can I get white label app for my business?",
      content: "Content for Accordion Item 3",
    },
    {
      title: "Who will manage my app?",
      content: "Content for Accordion Item 3",
    },
    {
      title: "Cancel I get refund of my payment?",
      content: "Content for Accordion Item 3",
    },
    {
      title: "Can I delist my app anytime?",
      content: "Content for Accordion Item 3",
    },
  ];

  // faq accordion

  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  //prices viewing with button change
  const [planType, setPlanType] = useState("2");

  return (
    <>
      <Header />
      <section>
        <div className={showLoaderAdmin ? "loading" : ""}></div>
        <div className="container">
          <div className="pricing_heading_wrapper col-md-9 mt-5">
            <h3>
              Transform your business strategy with Shoopy's adaptable plans and
              unlock new levels of success.
            </h3>
            <div className="pricing_range_buttons">
              <button
                onClick={() => handlePlanChange("2")}
                style={{
                  backgroundColor: planType === "2" ? "#0376f2" : "white",
                  color: planType === "2" ? "var(--white)" : "grey",
                  border: planType === "2" ? "none" : "",
                }}
              >
                Yearly
              </button>
              {/* <button
                onClick={() => handlePlanChange("1")}
                style={{
                  backgroundColor: planType === "1" ? "#0376f2" : "white",
                  color: planType === "1" ? "var(--white)" : "grey",
                  border: planType === "1" ? "none" : "",
                }}
              >
                Quarterly
              </button>
              <button
                onClick={() => handlePlanChange("0")}
                style={{
                  backgroundColor: planType === "0" ? "#0376f2" : "white",
                  color: planType === "0" ? "var(--white)" : "grey",
                  border: planType === "0" ? "none" : "",
                }}
              >
                Monthly
              </button> */}
            </div>
          </div>
          <div className="section_wrapper row">
            <div className="pricing_card_section ">
              <div className="container">
                <div className="row justify-content-center mt-4">
                  {searchfilter &&
                    searchfilter.length > 0 &&
                    searchfilter.map((option, index) => (
                      <div
                        className="col-xl-3 col-lg-6 col-md-6 col-12 mb-3"
                        key={index}
                      >
                        <div className="card">
                          <div className="card-body card_body">
                            <h6 className="card-title">
                              {option.package_name}
                            </h6>
                            <h3>{option.package_price}/-</h3>
                            <div className="select_planButton">
                              <button
                                className=""
                                type="button"
                                // onClick={() => openModel(option)}
                                onClick={() => handleLinkClick("contactUs")}
                              >
                                Select Plan
                              </button>
                            </div>
                            <div className="benefits single_li_card">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    option.description
                                  ),
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*----------- frequently asked questions ----------  */}
      <section className="faq_section">
        <div className="container">
          <div className="faq_section_container">
            <div className="faq_headings">
              <div className="faq_main_heading">
                <h3>Frequently Asked Questions</h3>
              </div>
              <div className="pricing_faq_subheading">
                <h6>Find your answer to frequently asked questions</h6>
              </div>
            </div>
            <div className="accordion_container col-md-14">
              <div className="accordion">
                {items.map((item, index) => (
                  <div key={index} className="accordion-item">
                    <div
                      className={`accordion-title ${
                        index === activeIndex ? "active" : ""
                      }`}
                      onClick={() => handleClick(index)}
                    >
                      {item.title}
                      <span className="dropdown-icon">
                        <img src={upArrow} alt="downarow" />
                      </span>
                    </div>
                    {index === activeIndex && (
                      <div className="accordion-content">
                        <p className="accordion-content-text">{item.content}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contactUs_section">
        <div className="container col-md-10">
          <div className="contactUs_container">
            <h3>We’re here to help build your brand</h3>
            <h6>
              Join Shopup and take your business online within 24 hours as other
              businesses with completely no code e-commerce platform
            </h6>
            <Link to="/contactUs">
              <button className="rounded-pill">Schedule a Demo</button>
            </Link>
          </div>
        </div>
      </section>
      <Modal
        show={showLoginModal}
        onHide={closeModel}
        className="login-Modal fade"
        centered
      >
        <Modal.Header
          className="login-modal-header "
          closeButton
        ></Modal.Header>
        <div className="col-12 col-md-12 m-0  p-0 h-100">
          <div className="container">
            <div
              className="requestCall"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className="container  cont2">
                <div className="requesTcollHeading">
                  <h5 style={{ fontWeight: "600" }}>
                    Request a Callback for {SelectedData.package_name}
                  </h5>
                </div>
                <div className="row inputsBox">
                  <form className="createRightsForm" id="createRightsForm">
                    <div className="col-12 mb-3">
                      <input
                        type="text"
                        name="user_name"
                        min={3}
                        max={10}
                        placeholder="Name"
                        className="form-control trio_mandatory"
                        onChange={handleAphabetsChange}
                      ></input>
                    </div>
                    <div className="inline-flex">
                      <div className="col-6 col-sm-6 mb-3">
                        <div className="input-container">
                          <input
                            type="text"
                            name="user_contact"
                            placeholder="Mobile No."
                            min={3}
                            max={10}
                            className="form-control trio_mandatory trio_no"
                            onChange={handleNumbersChange}
                          ></input>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 mb-3">
                        <div className="input-container">
                          <input
                            type="text"
                            name="user_alternate_contact"
                            placeholder="Alternate Mob. No."
                            min={3}
                            max={12}
                            className="form-control trio_no"
                            onChange={handleNumbersChange}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="col-12  mb-3">
                      <input
                        type="text"
                        name="user_email"
                        placeholder="Enter Email"
                        min={3}
                        className="form-control trio_mandatory"
                        onChange={handleEmailChange}
                      ></input>
                    </div>
                    <div className="col-12  mb-3">
                      <input
                        type="text"
                        name="user_city"
                        placeholder="City"
                        min={3}
                        max={10}
                        className="form-control trio_mandatory"
                      ></input>
                    </div>
                    <div className="col-12 mb-3">
                      <textarea
                        style={{ height: "100px" }}
                        type="text"
                        name="user_msg"
                        min={3}
                        max={200}
                        placeholder="Enter your Query"
                        className="form-control trio_mandatory"
                      ></textarea>
                    </div>
                  </form>
                </div>
                <div className="SubmitBtn">
                  <button
                    type="btn"
                    onClick={() =>
                      handleSaveChangesdynamic(
                        "createRightsForm",
                        intent_for_payment
                      )
                    }
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* footer code starts */}
      <Footer />
    </>
  );
};

export default Pricing;
